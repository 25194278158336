import * as app from './app';
import * as auth from './auth';
import * as achievement from './achievement';
import * as admin from './user';
import * as economy from './economy';
import * as clientVersion from './clientVersion';
import * as cloudCode from './cloudCode';
import * as player from './player';
import * as season from './season';
import * as localStorage from './localStorage';
import * as statistic from './statistic';
import * as titleData from './titleData';
import * as titleNews from './titleNews';
import * as devOps from './devOps';
import * as group from './group';
import * as title from './title';
import * as liveEvent from './liveEvents';
import * as localization from './localization';
import * as segment from './segment';
import * as cdn from './cdn';
import * as compensationData from './compensationData';
import * as costumerSupport from './costumerSupport';
import * as promoCampaign from './promoCampaign';

import { Request } from '../utils';
import { dispatch, setLoading } from '../reducers';
import { UserActionType, AppActionType } from '../enums';

import * as _ from 'lodash';

const actions = {
    app,
    achievement,
    title,
    auth,
    admin,
    economy,
    devOps,
    group,
    cloudCode,
    player,
    season,
    localStorage,
    statistic,
    titleData,
    titleNews,
    clientVersion,
    liveEvent,
    localization,
    segment,
    cdn,
    compensationData,
    costumerSupport,
    promoCampaign,
};

export type Actions = typeof actions;

const onReject = (err: any) => {
    try {
        const error: Error = new Error(err.response.data.errorMessage);
        if (error.message === 'No session!') {
            dispatch({
                type: UserActionType.UpdateLoginUser,
                payload: {
                    loginUser: null,
                },
            });
        }
        setError(error);
    } catch (e) {
        console.error(err);
        setError();
    }
};

export const setError = (error?: Error) => {
    dispatch({
        type: AppActionType.Update,
        payload: {
            loading: false,
            error
        },
    });
};

export class ActionRequest {
    static async get<T = any>(url: string, params?: any, opts?: any): Promise<T | null> {
        try {
            return await Request.get<T>(url, params, opts);
        } catch (error) {
            onReject(error);
            return null;
        }
    }

    static async post<T = any>(url: string, data?: any, opts?: any): Promise<T | null> {
        try {
            return await Request.post<T>(url, data, opts);
        } catch (error) {
            onReject(error);
            return null;
        }
    }

    static async postForm<T = any>(url: string, formData?: FormData): Promise<T | null> {
        try {
            return await Request.postForm<T>(url, formData);
        } catch (error) {
            onReject(error);
            return null;
        }
    }
}

const actionMap: Actions = _.mapValues(actions, map =>
    _.mapValues(
        map,
        func =>
            typeof func !== 'function'
                ? func
                : (...args: any[]) => {
                        setLoading(true);
                        // tslint:disable-next-line:ban-types
                        const result = (func as Function).apply(null, args);
                        if (!result || !result.then) {
                            setLoading(false);
                            return result;
                        }
                        return result.then(() => setLoading(false)).catch(onReject);
                  },
    ),
) as Actions;
export default actionMap;
