import * as React from 'react';
import { Button, TextField } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Grid, InputLabel } from '@mui/material';

interface Props extends BaseDialogProps {
    readonly onCreate: (key: string, english: string, spanishMexican: string, spanishSpain: string, deutsch: string, french: string, italian: string, portugueseBrazil: string) => void;
}

interface State extends BaseDialogState {
    key: string;
    english: string;
    spanishMexican: string;
    spanishSpain: string;
    deutsch: string;
    french: string;
    italian: string;
    portugueseBrazil: string;
}

export class CreateLocalizationDialog extends BaseDialog<Props, State> {
    state = this.initState();

    protected renderContent(): React.JSX.Element {
        const { key, english, spanishMexican, spanishSpain, deutsch, french, italian, portugueseBrazil } = this.state;
        return (
            <Grid container={true} spacing={0}>
                <Grid item={true} xs={12}>
                    <InputLabel>Key</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        fullWidth={true}
                        value={key}
                        label=""
                        variant="outlined"
                        required={true}
                        onChange={this.onUpdateKey}
                        disabled={false}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>English</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        fullWidth={true}
                        value={english}
                        label=""
                        variant="outlined"
                        required={true}
                        onChange={this.onUpdateEnglish}
                        disabled={false}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Mexican Spanish</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        fullWidth={true}
                        value={spanishMexican}
                        label=""
                        variant="outlined"
                        required={true}
                        onChange={this.onUpdateSpanishMexican}
                        disabled={false}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Spain Spanish</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        fullWidth={true}
                        value={spanishSpain}
                        label=""
                        variant="outlined"
                        required={true}
                        onChange={this.onUpdateSpanishSpain}
                        disabled={false}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Deutsch</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        fullWidth={true}
                        value={deutsch}
                        label=""
                        variant="outlined"
                        required={true}
                        onChange={this.onUpdateDeutsch}
                        disabled={false}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Italian</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        fullWidth={true}
                        value={italian}
                        label=""
                        variant="outlined"
                        required={true}
                        onChange={this.onUpdateItalian}
                        disabled={false}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>French</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        fullWidth={true}
                        value={french}
                        label=""
                        variant="outlined"
                        required={true}
                        onChange={this.onUpdateFrench}
                        disabled={false}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Brazil Portuguese</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        fullWidth={true}
                        value={portugueseBrazil}
                        label=""
                        variant="outlined"
                        required={true}
                        onChange={this.onUpdatePortugueseBrazil}
                        disabled={false}
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Create" onClick={this.onSubmit} variant="text" color="secondary" disabled={!this.validate()}/>
        );
    }

    protected initState(): State {
        return {
            key: '',
            english: '',
            spanishMexican: '',
            spanishSpain: '',
            deutsch: '',
            french: '',
            italian: '',
            portugueseBrazil: '',
        };
    }

    private validate(): boolean {
        const { key } = this.state;
        if (!key || key === '') {
            return false;
        }
        return true;
    }

    private onUpdateKey = (key: string) => this.setState({ key });

    private onUpdateEnglish = (english: string) => this.setState({ english });

    private onUpdateSpanishMexican = (spanishMexican: string) => this.setState({ spanishMexican });

    private onUpdateSpanishSpain = (spanishSpain: string) => this.setState({ spanishSpain });

    private onUpdateDeutsch = (deutsch: string) => this.setState({ deutsch });

    private onUpdateFrench = (french: string) => this.setState({ french });

    private onUpdateItalian = (italian: string) => this.setState({ italian });

    private onUpdatePortugueseBrazil = (portugueseBrazil: string) => this.setState({ portugueseBrazil });

    private onSubmit = () => {
        const { key, english, spanishMexican, spanishSpain, deutsch, french, italian, portugueseBrazil } = this.state;

        this.props.onCreate(key, english, spanishMexican, spanishSpain, deutsch, french, italian, portugueseBrazil);
        this.onClose();
    };
}
